:root {
  --sb-clr-brand: #B33771;
  --sb-clr-hover: #3C37B3;
  --sb-clr-footer: #2C3A47;
}

.App span
{
  color: whitesmoke;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  flex-direction: column;
  align-items: center;
}

.App-span-dark
{
  color:black;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  flex-direction: column;
  align-items: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-clear
{
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.App-clear-center
{
  margin-top: 0.4rem;
  margin-bottom: 0.2rem;
  text-align: center;
}

.App-sum-demo
{
  border: 1px dotted var(--sb-clr-brand);
  margin: 1.5rem 0rem;
  padding: 0.2rem 0rem;
  min-height: auto;
}

.form-font-family {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Not San', sans-serif;
}
.App-col-form-out
{
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 1px #e3f2fd;
  border-style: solid;
  border-radius: 0.5rem;
  margin: 0rem auto 0rem auto;
  padding-bottom: 0.2rem;
}

.App-col-form-in
{
  background-color: white;
  border: 1px #e3f2fd;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-style: solid;
  border-radius: 0.5rem;
  margin: 0rem auto 0rem auto;
}

.App-cont {
  box-shadow: 1rem;
  padding-bottom: 1.5rem;
}

.App-feedback {
  margin: 0.2rem 0.2rem;
  padding: 0.2rem;
  
  max-width: 100%;
  background-color: whitesmoke;
}

.App-hero-cont {
  box-shadow: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  
}

.App-pages-cont {
  box-shadow: 1rem;
  padding: 1rem 1.5rem;
  max-width: 64rem;
}

.App-video-cont div {
  padding: 1rem;
  text-align: center;
  margin: auto;
}

.App-video-cont h2 {
  font-size: x-large;
}

.App-video h5 {
  font-size: large !important;
}

.App-hero-cont p {
  padding-left: 2rem;
  text-align: left;
  font-family: 'Noto Serif', 'FB_NOTO_SERIF_1', 'FB_NOTO_SERIF_2', serif;
}

.App-hero-cont h1, h2 {
  padding: 0.2rem 2rem 2rem 1rem;
  
}

.App-pages-cont h1, h2, h3 {
  padding-bottom: 2rem;
}

.App-cont h2 {
  padding-bottom: 0.2rem;
  text-align: left;
}
.App-cont h3, h4{
  color: darkslategray;
  text-align: left;
}
.App-pages-col {
  padding-bottom: 0rem 0.2rem 2rem 0.2rem;
}

.App-pages-col h1, h2, h3 {
  padding: 2rem 0rem;
  text-align: left;

}

.App-pages-col h4, h5, h6 {
  padding-bottom: 1rem;
  text-align: left;
}

.App-pages-col ul, li {
  padding: 0.2rem 0rem;
}

.App-pages-col p {
  font-size: medium;
}

.App-pages-col div {
  max-width: 48rem;
}

.App-pages-col img {
  padding: 0.5rem;
}

.navbar-brand {
  display: flex;
  align-items: center;
}
.navbar-brand img {
  align-items: top;
  margin-right: 0.8rem;
}

.navbar-default .navbar-nav > li > a {
  color: white;
}

.footer{
  background-color: var(--sb-clr-footer);
  padding: 0.1rem; /*TRBL*/
  font-size: 90%;
  margin-top: auto;
}

/*Color footer links including hover */
.App-cont .nav .nav-link {
  padding: 0.1rem 0.2rem 0rem 0.2rem;
  color: whitesmoke;
  font-weight: 500;
}

.App-cont .nav .nav-link:hover {
  color: var(--sb-clr-brand);
}

.App-cont .copyright
{
  color: whitesmoke;
  font-size: small;
  font-weight: bold;
  padding-left: 0.4rem;
  padding-top: 0.8rem;
}

/* Variant sassbook is defined for each top container to avoid having to
use !important
*/
.App-cont .btn-sassbook
{
  background-color: var(--sb-clr-brand);
  border-color: wheat;
  color: whitesmoke;
}

.App-cont .btn-sassbook:hover
{
  background-color: var(--sb-clr-brand);
  border-color: var(--sb-clr-hover);
  color: wheat;
}

.btn-sassbook-radio
{
  background-color: #e3f2fd;
  border-color: #1976d2;
  color: slategray;
}

.btn-sassbook-radio-checked
{
  background-color: #1976d2;
  border-color: #1976d2;
  color: whitesmoke;
}

.App-cont .btn-sassbook-dropdown
{
  background-color: #1976d2;
  border-color: #1976d2;
  color: whitesmoke;
}

.App .btn-sassbook
{
  background-color: var(--sb-clr-brand);
  border-color: wheat;
  color: whitesmoke;
}

.App .btn-sassbook:hover
{
  background-color: var(--sb-clr-brand);
  border-color: blue;
  color: wheat;
}

.App-video .btn-sassbook
{
  background-color: var(--sb-clr-brand);
  border-color: wheat;
  color: whitesmoke;
}

.parent .App .sbcards
{
  text-align: left;
  text-justify: auto;
  padding: 1rem 1rem 2rem 1rem;
}

.parent .App .sbcards .card-img-top {
  width: 40% ;
  margin-left: 30%;
  height: auto;
  padding:1rem 0.2rem;
}

.parent .App .sbcards .card-text {
  max-width: 32rem;
}

.fbui {
  margin-top: 2rem;
  margin-top: 1rem;
  padding: 1rem;
  border: 1px var(--sb-clr-brand);
  border-style: solid;
  border-radius: 1rem;
  background-color: lavenderblush;
}

/*To push social links to the right */
.social
{
  display: flex;
}
 span:first-of-type {
  flex: 1;
}
/*End: To push social links to the right */
.App-video
{
  padding: 0.6rem;
  border-radius: 0.5rem;
  background-color: whitesmoke;
  margin: 0.5rem auto 1rem auto; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.App-video h5
{
  font-size: large;
}

/*Color top navigator links including hover */
.App .navbar-light .navbar-nav .nav-link {
  color: var(--sb-clr-brand);
  font-weight: 600;
}

.App .navbar-light .navbar-nav .nav-link:hover {
  color:  var(--sb-clr-footer);
}

.form-label 
{
  font-weight: 500;
  float: center;
}

.gen-carousel-item  {
  color: var(--sb-clr-footer);
  background-color: #f7f7f7;
  padding: 0.2rem;
  margin-bottom: 0.15rem;
  border: 0.1rem solid lightblue;
  border-radius: 0.3rem;
  font-family: "Arial";
  font-size: medium;
  list-style-position:inside;
  list-style: none;
  text-align: left;
}


.gen-carousel-item-selected  {
  color: whitesmoke;
  background-color: var(--sb-clr-brand);
  padding: 0.2rem;
  margin-bottom: 0.15rem;
  border: 0.1rem solid wheat;
  border-radius: 0.3rem;
  font-family: "Arial";
  list-style-position:inside;
  list-style: none;
  text-align: left;  
}


.undo
{
  padding: 0.2rem;
  margin: 0rem 0.2rem 0rem 0.1rem;
}

.rowC {
  width: 100%;
  margin: 0;
  display:flex;
  flex-direction:row;
}


.App-cont-products {
  box-shadow: 1rem;
  margin: auto;
  padding: 0 1rem;
  width: 100%;
}

.App-cont-products .btn-sassbook-radio
{
  background-color: #e3f2fd;
  border-color: #1976d2;
  color: slategray;
}

.App-cont-products .btn-sassbook-radio.active
{
  background-color: #1976d2;
  border-color: #1976d2;
  color: whitesmoke;
}

.App-product
{
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 1px #e3f2fd solid; 
  border-radius: 0.5rem;
  margin: 0rem auto .5rem auto;
  padding: 0.2rem;
  font-size: smaller;
}

.App-product-selected
{
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 2px #750672 solid;
  border-radius: 0.5rem;
  margin: 0rem 0.5rem .5rem 0.5rem;
  padding: 0.2rem;
}

.App-product h4, .App-product-selected h4 {
  background-color: #dcf1ff;
  font-size: x-large;
  font-style: normal;
  font-weight: bolder;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}
.App-product h2, .App-product-selected h2 {
  background-color: whitesmoke;
  font-size: x-large;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}

.App-product h6, .App-product-selected h6 {
  text-align: center;
}

.subs-required-message {
  font-size: 85%;
  background-color: darkslategray;
  color: whitesmoke;
  width: 90%;
  margin: auto;
  border-radius: 1rem;
;
}

.gen-carousel-row {
  width: 100%;
  margin-top: 0.5em;
  display:flex;
  flex-direction:row;
}

.gen-carousel-number {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.1em;
  background-color: var(--sb-clr-footer);
  color: wheat;
  padding: 0.2em;
  border-radius: 0.3rem;
}

.App .App-pages-cont .btn-link {
  text-decoration: none !important;
  color: var(--sb-clr-brand);
  font-weight: bold;
}

.App .App-pages-cont .btn-link:hover {
  text-decoration: none !important;
  color: var(--sb-clr-hover);
  font-weight: bold;
}

.App .App-cont-products .btn-link {
  text-decoration: none !important;
  color: var(--sb-clr-brand);
  font-weight: bold;
}
.App .App-cont-products .btn-link:hover {
  text-decoration: none !important;
  color: var(--sb-clr-hover);
  font-weight: bold;
}
.App .App-cont-products h4 {
  justify-content: left;
  padding: 1rem 0;
}

.toast {
  background-color:#ece9e3 !important;
}
.toast-header {
  background-color: slategray !important;
  color: whitesmoke !important;
}

.accordion-button {
  color: var(--sb-clr-brand);
  font-weight: bold;
}
.accordion-button:hover,:focus  {
  color: var(--sb-clr-hover);
  font-weight: bold;
}
